.text-link-img-container {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;

  img {
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    max-height: none;
  }
}