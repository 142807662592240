.card-info {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  height: 100%;
  border-radius: 16px;

  &--half {
    @media screen and (min-width: 768px) {
      height: 50%;
    }
  }

  &__title {
    font-family: 'UnimedSans-2020-SemiBoldCondensed', Arial, sans-serif !important;
    font-size: 0.75rem;
    line-height: 1.1;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      font-size: 0.778rem;
    }
  }

  &__content {
    font-family: 'UnimedSans-2020-Book', Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 1.38;

    @media screen and (min-width: 768px) {
      font-size: 1.333rem;
    }
  }

  &__text {
    font-size: 0.887rem;
    font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;

    @media screen and (min-width: 768px) {
      margin-top: auto;
    }
  }
}
