.ContentBlock {
  &.bloco-conteudo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .bloco-conteudo-title {
      font-family: "UnimedSlab-2020-Bold", Arial, sans-serif;
      font-size: 1.778rem;
      line-height: 1.38;
      margin-bottom: 8px;

      @media screen and (min-width: 768px) {
        font-size: 1.778rem;
        line-height: 1.38;
      }
    }

    .bloco-conteudo-section-title {
      font-family: "UnimedSlab-2020-SemiBold", Arial, sans-serif;
      font-size: 1.675rem;
      line-height: 1.38;
      margin-bottom: 8px;

      @media screen and (min-width: 768px) {
        font-size: 2.333rem;
        line-height: 1.38;
      }
    }

    .bloco-conteudo-preview {
      font-family: "UnimedSans-2020-SemiBold", Arial, sans-serif;
      font-size: 1rem;
      line-height: 1.39;
      margin-bottom: 16px;
    }

    .bloco-conteudo-text {
      font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
      font-size: 1rem;
      line-height: 1.29;
      letter-spacing: 0.25px;
      margin-bottom: 16px;
    }

    .bloco-conteudo-footer {
      margin-top: auto;
    }

    .bloco-conteudo-lista {
      padding-inline-start: 18px;
      color: var(--gray-100);

      li {
        p {
          font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
          font-size: 1rem;
          color: var(--gray-100);
        }
      }
    }
  }
}
